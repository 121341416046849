import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'
import mainHeader from '../../images/solutions/okrMain.jpeg'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { FaqSection } from '../../components/faq'
import clsx from 'clsx'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { controladoriaList } from '../../utils/customersList'

const faqItems = [
  {
    question: 'Quais os benefícios da consultoria em gestão empresarial?',
    answer: `A implementação de boas práticas de planejamento e gestão empresarial possibilita alavancar os resultados de uma organização, reduzindo custos, aumentando a produtividade e descentralizando a tomada de decisões.
			a definição de metas ousadas e ao mesmo tempo alcançáveis, a tomada de decisões de forma assertiva, mensuração do desempenho da equipe e identificação de melhorias, visão ampla de processos e maior motivação e engajamento de colaboradores.
			`,
  },
  {
    question: 'Quanto tempo leva o projeto de consultoria?',
    answer:
      'Depende do nível de maturidade de gestão da empresa. Projetos como esse levam em torno de 6 a 12 meses, sendo possível realizar a renovação da contratação. Trabalhamos de forma ágil para que a cada reunião de status do projeto possamos evoluir para os resultados.',
  },
  {
    question: 'Quais os fatores de sucesso do projeto de consultoria?',
    answer:
      'Para colher os frutos do trabalho de consultoria é preciso que a alta liderança esteja engajada a implementar mudanças na organização, que podem implicar em adoção de novas tecnologias, realocação de pessoas, investimento em treinamentos e até mesmo questões de ordem comportamental e cultural.',
  },
  {
    question: 'Como entender se é o momento de contratar uma consultoria?',
    answer:
      'Existem inúmeras razões para se contratar uma consultoria empresarial, das quais, podemos citar o crescimento repentino da empresa, a sucessão de pais para filhos, a fusão ou cisão entre empresas, o aporte de investimentos, a necessidade de profissionalização da gestão, dentre outros.',
  },
]

const featuresItems = [
  {
    title: `Foco e produtividade`,
    description: (
      <>
        A metodologia OKR proporcionará mais engajamento aos colaboradores, pois
        objetivos bem definidos trazem motivação e propósito para as equipes.
        Além disso, haverá um melhor alinhamento entre as equipes e a empresa,
        fazendo com que todos caminhem para a mesma direção.
      </>
    ),
  },
  {
    title: `Metodologia ágil`,
    description: (
      <>
        Diferentemente das metodologias de gestão tradicionais, com
        planejamentos anuais estáticos, o OKR usa uma abordagem ágil através da
        execução de ciclos de metas curtos, para que a empresa possa se adaptar
        e responder com mais velocidade às mudanças.
      </>
    ),
  },
  {
    title: `Transparência e democratização`,
    description: (
      <>
        O propósito do OKR é criar um alinhamento na organização. Para isso, os
        OKRs são públicos para todos os níveis da empresa - do estratégico ao
        operacional. É boa prática utilizar a gestão à vista para divulgação a
        todos os colaboradores, seja por meio de dashboards impressos ou online,
        na intranet.
      </>
    ),
  },
  {
    title: `Metas ambiciosas`,
    description: (
      <>
        A filosofia por trás da metodologia OKR requer metas ambiciosas, afinal
        de contas, se a empresa está alcançando sempre 100% das metas, é bem
        provável de que estas sejam muito fáceis. Metas desafiadoras fazem os
        times repensarem sua maneira de trabalhar, para maximizar o desempenho.
      </>
    ),
  },
]

const OkrPage: React.FC<PageProps> = ({ location }) => {
  const methodologyItems = [
    {
      count: 1,
      title: 'Treinamento',
      description: (
        <>
          A primeira etapa da implantação da metodologia consiste no treinamento
          e engajamento de todos os colaboradores, trabalhando os principais
          conceitos e fornecendo templates para a criação de objetivos e
          resultados chaves nos níveis estratégico, tático e operacional.
        </>
      ),
    },
    {
      count: 2,
      title: 'Criação e ação',
      description: (
        <>
          Na segunda etapa serão definidos os objetivos e respectivos resultados
          chaves. Os objetivos devem ser simples, curtos e fáceis de memorizar.
          Já os resultados chaves são métricas, e não um descritivo de tarefas a
          serem realizadas.
        </>
      ),
    },
    {
      count: 3,
      title: 'Monitoramento e análise de resultados',
      description: (
        <>
          OKRs são periodicamente definidos, medidos e reavaliados - geralmente
          trimestralmente. É importante fazer o monitoramento em ciclos curtos,
          porque vivemos em uma sociedade volátil, os objetivos mudam
          organicamente e se o ciclo for muito extenso, há o risco de que os
          objetivos não façam mais sentido para a organização.
        </>
      ),
    },
    {
      count: 4,
      title: 'Identificar melhorias',
      description: (
        <>
          Nesta etapa é o momento de identificar melhorias e aplicá-las, fazendo
          ajustes, definindo padrões, priorizando objetivos, permeando a
          metodologia implantada na cultura da organização.
        </>
      ),
    },
  ]

  return (
    <Layout location={location}>
      <SEO title="High Jump - Sistema de Gestão Integrado" />
      <div className={styles.solutions}>
        <Menu mainFolder="OKR" />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>
                OKR, objetivos e resultados chaves que conduzem ao sucesso
              </h1>
              <p>
                Transforme sua estratégia em resultados, simplifique o
                planejamento e execução de tarefas, impondo uma disciplina
                contínua.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Propor soluções através do uso da metodologia de gestão ágil
                  OKR, simplificando o planejamento e execução de tarefas para
                  alavancar resultados.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça agora os 04 principais passos de nossa metodologia para
            implementar a gestão OKR em sua organização."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={controladoriaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Boas práticas FIPECAFI</h2>
                  <p>Aplicação de boas práticas contábeis e fiscais</p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Almejando o retorno sobre o investimento</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default OkrPage
